import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto" }
const _hoisted_2 = { class: "fv-row mb-10" }
const _hoisted_3 = { class: "password-checker mb-10 p-3 ps-5" }
const _hoisted_4 = { class: "fv-row mb-10" }
const _hoisted_5 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" }
const _hoisted_6 = {
  key: 0,
  type: "submit",
  ref: "submitButton",
  id: "kt_change_password_submit",
  class: "btn btn-lg btn-primary fw-bolder me-4"
}
const _hoisted_7 = {
  key: 1,
  disabled: "",
  type: "submit",
  ref: "submitButton",
  id: "kt_change_password_submit",
  class: "btn btn-lg btn-primary fw-bolder me-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework",
      onSubmit: _ctx.onSubmitChangePassword,
      id: "kt_change_password_form",
      "validation-schema": _ctx.changePassword
    }, {
      default: _withCtx(() => [
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-center mb-10" }, [
          _createElementVNode("h1", { class: "text-dark mb-3" }, "Reset Password"),
          _createElementVNode("div", { class: "text-gray-400 fw-bold fs-4" }, "Please enter your new password.")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label fw-bolder text-gray-900 fs-6" }, "New Password", -1)),
          _createVNode(_component_Field, {
            class: "form-control form-control-solid",
            type: _ctx.passwordType,
            placeholder: "",
            name: "password",
            autocomplete: "off",
            onKeyup: _ctx.checkPassword,
            onChange: _ctx.checkPassword,
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event))
          }, null, 8, ["type", "onKeyup", "onChange", "modelValue"]),
          _createElementVNode("i", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.show_password && _ctx.show_password(...args))),
            class: _normalizeClass(["far fa-eye password-eye", { 'text-primary': _ctx.activeEye }])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", {
            class: _normalizeClass(["pc-line mb-1", { 'text-primary fw-bold': _ctx.has_lowercase, 'text-gray-400': !_ctx.has_lowercase }])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", { 'text-primary fa-check': _ctx.has_lowercase, 'text-gray-400 fa-times': !_ctx.has_lowercase }])
            }, null, 2),
            _cache[5] || (_cache[5] = _createTextVNode(" A lowercase letter "))
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["pc-line mb-1", { 'text-primary fw-bold': _ctx.has_uppercase, 'text-gray-400': !_ctx.has_uppercase }])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", { 'text-primary fa-check': _ctx.has_uppercase, 'text-gray-400 fa-times': !_ctx.has_uppercase }])
            }, null, 2),
            _cache[6] || (_cache[6] = _createTextVNode(" An uppercase letter "))
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["pc-line mb-1", { 'text-primary fw-bold': _ctx.has_number, 'text-gray-400': !_ctx.has_number }])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", { 'text-primary fa-check': _ctx.has_number, 'text-gray-400 fa-times': !_ctx.has_number }])
            }, null, 2),
            _cache[7] || (_cache[7] = _createTextVNode(" A number "))
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["pc-line mb-1", { 'text-primary fw-bold': _ctx.has_special, 'text-gray-400': !_ctx.has_special }])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", { 'text-primary fa-check': _ctx.has_special, 'text-gray-400 fa-times': !_ctx.has_special }])
            }, null, 2),
            _cache[8] || (_cache[8] = _createTextVNode(" A special character "))
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["pc-line mb-1", { 'text-primary fw-bold': _ctx.passwordLength, 'text-gray-400': !_ctx.passwordLength }])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", { 'text-primary fa-check': _ctx.passwordLength, 'text-gray-400 fa-times': !_ctx.passwordLength }])
            }, null, 2),
            _cache[9] || (_cache[9] = _createTextVNode(" Minimum 8 characters "))
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["pc-line mb-1", { 'text-primary fw-bold': _ctx.passwordMatch, 'text-gray-400': !_ctx.passwordMatch }])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", { 'text-primary fa-check': _ctx.passwordMatch, 'text-gray-400 fa-times': !_ctx.passwordMatch }])
            }, null, 2),
            _cache[10] || (_cache[10] = _createTextVNode(" Passwords match "))
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label fw-bolder text-gray-900 fs-6" }, "Confirm your password", -1)),
          _createVNode(_component_Field, {
            class: "form-control form-control-solid",
            type: _ctx.passwordConfirmType,
            placeholder: "",
            name: "confirmPassword",
            autocomplete: "off",
            onKeyup: _ctx.checkPassword,
            onChange: _ctx.checkPassword,
            modelValue: _ctx.passwordConfirmed,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordConfirmed) = $event))
          }, null, 8, ["type", "onKeyup", "onChange", "modelValue"]),
          _createElementVNode("i", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.show_confirmed_password && _ctx.show_confirmed_password(...args))),
            class: _normalizeClass(["far fa-eye password-eye", { 'text-primary': _ctx.activeConfirmEye }])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.enabledbutton)
            ? (_openBlock(), _createElementBlock("button", _hoisted_6, _cache[12] || (_cache[12] = [
                _createElementVNode("span", { class: "indicator-label" }, " Reset Password ", -1),
                _createElementVNode("span", { class: "indicator-progress" }, [
                  _createTextVNode(" Please wait... "),
                  _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                ], -1)
              ]), 512))
            : _createCommentVNode("", true),
          (!_ctx.enabledbutton)
            ? (_openBlock(), _createElementBlock("button", _hoisted_7, _cache[13] || (_cache[13] = [
                _createElementVNode("span", { class: "indicator-label" }, " Reset Password ", -1)
              ]), 512))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"])
  ]))
}